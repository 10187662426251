// eslint-disable-next-line no-unused-vars
import slick from 'slick-carousel';


export default class Sliders {
  constructor() {
      this.slider = $('.slider');
      this.sliderSector = $('.sector-slide');
    this.init();
  }
  init = () => {
        this.slider.slick({
            dots: false,
            infinite: false,
            speed: 800,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: '<a class="next"><div class="arrow-wrap"><span class="icon-right-icon"></span></div></a>',
            prevArrow: '<a class="prev"><div class="arrow-wrap"><span class="icon-left-icon"></span></div></a>',
          // the magic
          responsive: [{
              breakpoint: 1200,
              settings: {
                  slidesToShow: 2
              }
          }, {

              breakpoint: 640,
              settings: {
                  slidesToShow: 1
              }
          }, {

              breakpoint: 300,
              settings: {
                  slidesToShow: 1
              }
          }]
      });

    this.sliderSector.slick({
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<a class="next"><div class="arrow-wrap"><span class="icon-right-arrow-icon"></span></div></a>',
        prevArrow: '<a class="prev"><div class="arrow-wrap"><span class="icon-left-arrow-icon"></span></div></a>',
        // the magic
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        }, {

            breakpoint: 992,
            settings: {
                slidesToShow: 2,
            }
        }, {

            breakpoint: 640,
            settings: {
                slidesToShow: 1
            }

        }]
    });
  }
}

