import skrollr from 'skrollr';
import AOS from 'aos';
import Header from './components/Header';
import Maps from './components/Maps';
import Sliders from './components/Sliders';
import LiquidButton from './components/LiquidButton';

import './external/bootstrap/bootstrap.min.css';
import './external/bootstrap/bootstrap';
import './external/slick/slick.css';
import './external/aos/aos.css';
import './external/fancybox/fancybox.css';
import './external/fancybox/fancybox';
import './external/chosen/chosen.css';
import './external/chosen/chosen';
import './external/animsition/animsition.css';
import './external/animsition/animsition';
import './external/mcustom/jquery.mCustomScrollbar.css';
import './external/mcustom/jquery.mousewheel.min';
import './external/mcustom/jquery.mCustomScrollbar';
import bodymovin from './external/bodymovin/lottie';

export default new class App {
  constructor() {
    this.setDomMap();
    this.previousScroll = 0;

    // dom ready shorthand
    $(() => {
      this.domReady();
    });
  }

  domReady = () => {
    this.initComponents();
    this.initExternalLibraries();
    this.handleUserAgent();
    this.windowResize();
    this.bindEvents();
    this.handleSplashScreen();
    // console.log('Initializing Dom events');
  };

  initExternalLibraries = () => {

  if ($(window).width() > 1200) {

    $(".animsition-overlay").animsition({
      inClass: 'overlay-slide-in-bottom',
      outClass: 'overlay-slide-out-bottom',
      inDuration: 1200,
      outDuration: 1200,
      timeout:true,
      timeoutCountdown: 1000,
      linkElement: '.animsition-link', //all links excluded this
      //loading: false,
      overlay: true,
      browser: [ 'animation-duration', '-webkit-animation-duration'],
      overlayClass: 'animsition-overlay-slide',
      overlayParentElement: 'body'
    });

    skrollr.init();
  }

  if ($(window).width() < 1200) {
    $("header .mobile-nav .nav li.nav-dropdown").click(function () {
      if (!$(this).hasClass("clicked")) {
        $("header .mobile-nav .nav li.nav-dropdown").removeClass("clicked");
        $(this).addClass("clicked");
        return false;
      }
    });
  }


  if($(".modal-block .modal-wrap .description").length) {
    $(".modal-block .modal-wrap .description").mCustomScrollbar({
      scrollInertia: 200,
      mouseWheel: {enable: true}
    });
  }

  $('[id*=experience-in-years]').keypress(validateNumber);
  $('[id*=phone]').keypress(validateNumber);


  function validateNumber(event) {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode != 8 && event.keyCode != 0 && (event.keyCode < 48 || event.keyCode > 57)) {
      return false;
    } else if (key < 48 || key > 57) {
      return false;
    } else {
      return true;
    }
  }



    $('.fancybox').fancybox();
    // require('fancybox')($);
    // fancybox.open($('.fancybox'));
    if ($('#anim-one').length) {
      const animone = document.getElementById('anim-one');
      bodymovin.loadAnimation({
        wrapper: animone,
        animType: 'svg',
        loop: true,
        path: `${CCM_APPLICATION_URL}/application/themes/lobo/src/js/data/c-one/data.json`,
      });
    }
    if ($('#anim-two').length) {
        const animone = document.getElementById('anim-two');
        bodymovin.loadAnimation({
            wrapper: animone,
            animType: 'svg',
            loop: true,
            path: `${CCM_APPLICATION_URL}/application/themes/lobo/src/js/data/c-two/data.json`,
        });
    }

    if ($('#anim-three').length) {
      const animone = document.getElementById('anim-three');
      bodymovin.loadAnimation({
        wrapper: animone,
        animType: 'svg',
        loop: true,
        path: `${CCM_APPLICATION_URL}/application/themes/lobo/src/js/data/c-three/data.json`,
      });
    }

  if ($('#anim-four').length) {
    const animone = document.getElementById('anim-four');
    bodymovin.loadAnimation({
      wrapper: animone,
      animType: 'svg',
      loop: true,
      path: `${CCM_APPLICATION_URL}/application/themes/lobo/src/js/data/c-four/data.json`,
    });
  }

  if ($('#anim-five').length) {
    const animone = document.getElementById('anim-five');
    bodymovin.loadAnimation({
      wrapper: animone,
      animType: 'svg',
      loop: true,
      path: `${CCM_APPLICATION_URL}/application/themes/lobo/src/js/data/c-five/data.json`,
    });
  }

  if ($('#anim-six').length) {
    const animone = document.getElementById('anim-six');
    bodymovin.loadAnimation({
      wrapper: animone,
      animType: 'svg',
      loop: true,
      path: `${CCM_APPLICATION_URL}/application/themes/lobo/src/js/data/c-six/data.json`,
    });
  }

  AOS.init();



    if ($('.edit-mode').length) {
      $('.tab-list li').click(function () {
        const pointIndex = $(this).index();
        $('.gallery-slider .g-image').eq(pointIndex).fadeIn('slow').siblings()
          .fadeOut('slow');
        if (!$(this).hasClass('active')) {
          $('.tab-list li').removeClass('active');
          $(this).addClass('active');
          $('.tab-pane').removeClass('active');
          $('.tab-pane').removeClass('in');
          $('.tab-pane').eq(pointIndex).addClass('active');
          $('.tab-pane').eq(pointIndex).addClass('in');
        }
      });
    }

    $('.formidable .element .radio').click(function () {
      if (!$(this).find('input').is(':checked')) {
        $('.formidable .element .radio').removeClass('checked');
        $(this).addClass('checked');
      } else {
        $('.formidable .element .radio').removeClass('checked');
        $(this).addClass('checked');
      }
    });

    // Chosen
    if ($(window).width() > 1200) {
      if ($('select').length) {
        $('select').chosen({
          disable_search_threshold: 25,
          search_contains: true,
        });
      }
    }


  $(".scroll-btn").click(function() {
    $('html, body').animate({
      scrollTop: $("#scroll").offset().top
    }, 1000);
  });

    if ($('#type').length) {
      $('#type').chosen({}).on('change', NewsPage.filterProgrammes);
    }
    // if ($('#job_location').length) {
    //   $('#job_location').chosen({}).on('change', (e) => {
    //     console.log($(e.currentTarget).val());
    //     console.log('change job location');
    //   });
    // }
    // if ($('#job_sector').length) {
    //   $('#job_sector').chosen({}).on('change', (e) => {
    //     console.log('change job sector');
    //   });
    // }
    // Chosen Ends
  }

  initComponents = () => {
    new Header({
      header: this.header,
      htmlBody: this.htmlBody,
    });
    new Sliders({

    });

    //new LiquidButton();

    // new bodyMovin({
    //
    // });

    if (this.mapContainer.length) {
      new Maps({
        mapContainer: this.mapContainer,
      });
    }
  };

  setDomMap = () => {
    this.window = $(window);
    this.htmlNbody = $('body, html');
    this.html = $('html');
    this.htmlBody = $('body');
    this.siteLoader = $('.site-loader');
    this.header = $('header');
    this.siteBody = $('.site-body');
    this.footer = $('footer');
    this.gotoTop = $('#gotoTop');
    this.gRecaptcha = $('.g-recaptcha');
    this.wrapper = $('.wrapper');
    this.pushDiv = this.wrapper.find('.push');
    this.mapContainer = $('#map_canvas');
    this.inputs = $('input, textarea').not('[type="checkbox"], [type="radio"]');
  };

  bindEvents = () => {
    // Window Events
    this.window.resize(this.windowResize).scroll(this.windowScroll);

    // General Events
    const $container = this.wrapper;

    $container.on('click', '.disabled', () => false);

    // Specific Events
    this.gotoTop.on('click', () => {
      this.htmlNbody.animate({
        scrollTop: 0,
      });
    });

    this.inputs
      .on({
        focus: (e) => {
          const self = $(e.currentTarget);
          self.closest('.element').addClass('active');
        },
        blur: (e) => {
          const self = $(e.currentTarget);
          if (self.val() !== '') {
            self.closest('.element').addClass('active');
          } else {
            self.closest('.element').removeClass('active');
          }
        },
      })
      .trigger('blur');

    // Reload the current path when changing language instead of redirecting to landing page
    // Uncomment below and modify languages
    // $container.on('click', '.language-toggle', function(e) {
    //   e.preventDefault();
    //   const $this = $(this);
    //   const href = $this.attr('href');
    //   const isEnglish = href.indexOf('/ar') >= 0;
    //   const locArray = location.pathname.split('/');
    //   const indexOfIndex = locArray.indexOf('index.php');
    //   const isDev = indexOfIndex >= 0;
    //   const index = isDev ? indexOfIndex + 1 : 1;
    //   if(!isEnglish) {
    //     locArray = locArray.filter(item => item !== 'ar')
    //   }
    //   locArray.splice(index, 0, isEnglish ? 'ar' : '');
    //   const newHref = locArray.join('/').replace('//', '/');
    //   location.href = newHref;
    // });

    // Uncomment below if you need to add google captcha (also in includes/script.php)
    // => Make sure the SITEKEY is changed below
    // this.gRecaptcha.each((index, el) => {
    //   grecaptcha.render(el, {'sitekey' : '6LeB3QwUAAAAADQMo87RIMbq0ZnUbPShlwCPZDTv'});
    // });
  };

  windowResize = () => {
    this.screenWidth = this.window.width();
    this.screenHeight = this.window.height();

    // calculate footer height and assign it to wrapper and push/footer div
    this.footerHeight = this.footer.outerHeight();
    this.wrapper.css('margin-bottom', -this.footerHeight);
    this.pushDiv.height(this.footerHeight);
  };

  windowScroll = () => {
    const topOffset = this.window.scrollTop();

    this.header.toggleClass('top', topOffset > 100);
    this.header.toggleClass('sticky', topOffset > 350);
    if (topOffset > this.previousScroll || topOffset < 250) {
      this.header.removeClass('sticky');
    } else if (topOffset < this.previousScroll) {
      this.header.addClass('sticky');
      // Additional checking so the header will not flicker
      if (topOffset > 250) {
        this.header.addClass('sticky');
      } else {
        this.header.removeClass('sticky');
      }
    }

    this.previousScroll = topOffset;
    this.gotoTop.toggleClass(
      'active',
      this.window.scrollTop() > this.screenHeight / 2,
    );
  };

  handleSplashScreen() {
    this.htmlBody.find('.logo-middle').fadeIn(500);
    this.siteLoader.delay(1500).fadeOut(500);
  }

  handleUserAgent = () => {
    // detect mobile platform
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      this.htmlBody.addClass('ios-device');
    }
    if (navigator.userAgent.match(/Android/i)) {
      this.htmlBody.addClass('android-device');
    }

    // detect desktop platform
    if (navigator.appVersion.indexOf('Win') !== -1) {
      this.htmlBody.addClass('win-os');
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      this.htmlBody.addClass('mac-os');
    }

    // detect IE 10 and 11P
    if (
      navigator.userAgent.indexOf('MSIE') !== -1
      || navigator.appVersion.indexOf('Trident/') > 0
    ) {
      this.html.addClass('ie10');
    }

    // detect IE Edge
    if (/Edge\/\d./i.test(navigator.userAgent)) {
      this.html.addClass('ieEdge');
    }

    // Specifically for IE8 (for replacing svg with png images)
    if (this.html.hasClass('ie8')) {
      const imgPath = '/themes/theedge/images/';
      $('header .logo a img,.loading-screen img').attr(
        'src',
        `${imgPath}logo.png`,
      );
    }

    // show ie overlay popup for incompatible browser
    if (this.html.hasClass('ie9')) {
      const message = $(
        '<div class="no-support"> You are using outdated browser. Please <a href="https://browsehappy.com/" target="_blank">update</a> your browser or <a href="https://browsehappy.com/" target="_blank">install</a> modern browser like Google Chrome or Firefox.<div>',
      );
      this.htmlBody.prepend(message);
    }
  };
}();
